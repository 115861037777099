/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:aeb7b058-2dd3-4ad2-8f26-62d3c052a194",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_o0aQGXPkp",
    "aws_user_pools_web_client_id": "pm3t3tkjmehfgto8t6l3rq9if",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "healthChecks",
            "endpoint": "https://scmkh130s6.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "websiteChecks",
            "endpoint": "https://lmw3nb08m1.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "healthChecks-dev",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
